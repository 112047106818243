import React from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import { nanoid } from 'nanoid';
// import PortfolioContext from '../../context/context';

const Contact = () => {
  // const { contact } = useContext(PortfolioContext);

  const { cta, btn, email, networks } = {
    cta: "I'm looking for opportunities!",
    btn: "Let's talk",
    email: 'owen.lenz@gmail.com',
    networks: [
      {
        id: nanoid(),
        name: 'envelope',
        url: 'owen.lenz@gmail.com',
      },
      {
        id: nanoid(),
        name: 'linkedin',
        url: 'https://www.linkedin.com/in/owen-lenz/',
      },
      {
        id: nanoid(),
        name: 'github',
        url: 'https://github.com/OwenKLenz',
      },
      {
        id: nanoid(),
        name: 'file-text',
        url: 'https://oklenz.com/Owen_Lenz_Resume.pdf',
      },
    ],
  };

  return (
    <section id="contact">
      <Container>
        <Fade bottom duration={800} delay={100} distance="30px">
          <div className="contact-wrapper">
            <p className="contact-wrapper__text">
              {cta || 'Would you like to work with me? Awesome!'}
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href={email ? `mailto:${email}` : 'https://github.com/cobidev/react-simplefolio'}
            >
              {btn || "Let's Talk"}
            </a>
          </div>
        </Fade>
        <Fade bottom duration={800} delay={100}>
          <div className="social-links">
            {networks &&
                networks.map((network) => {
                  const { id, name, url } = network;
                  return (
                    <a
                      key={id}
                      href={(name === 'envelope' ? 'mailto:' : '') + url}
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label={name}
                    >
                      <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                    </a>
                  );
                })}
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Contact;
