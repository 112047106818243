import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero';
import About from './About/About';
import Jolt from './Jolt/Jolt';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact';

// import { PortfolioProvider } from '../context/context';

// import { heroData, aboutData, projectsData, contactData, joltData } from '../mock/data';

function App() {
  // const [hero, setHero] = useState({});
  // const [about, setAbout] = useState({});
  // const [jolt, setJolt] = useState({});
  // const [projects, setProjects] = useState([]);
  // const [contact, setContact] = useState({});

  // useEffect(() => {
  //   setHero({ ...heroData });
  //   setAbout({ ...aboutData });
  //   setJolt({ ...joltData });
  //   setProjects([...projectsData]);
  //   setContact({ ...contactData });
  // }, []);

  return (
    <>
    {/* <PortfolioProvider value={{ hero, about, jolt, projects, contact }}> */}
      <Hero />
      <About />
      <Jolt />
      <Projects />
      <Contact />
    {/*</PortfolioProvider> */}
    </>
  );
}

export default App;
