import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
// import PortfolioContext from '../../context/context';

const Header = () => {
  // const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle } = {
    title: 'Hello, my name is',
    name: 'Owen',
    subtitle: "I'm a Software Engineer.",
    cta: 'About',
  };

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            {title || 'Hi, my name is'}{' '}
            <span className="text-color-main">{name || 'Your Name'}</span>!
            <br />
            {subtitle || "I'm the Unknown Developer."}
          </h1>
        </Fade>
        <span className="scroll-down">
          <Link to="about" smooth duration={1000}>
            <i className="fa fa-angle-down fa-2x" aria-hidden="true" />
          </Link>
        </span>
      </Container>
    </section>
  );
};

export default Header;
