import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Tilt from 'react-tilt';
// import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import { nanoid } from 'nanoid';
import jolt_deploy_gif from "../../images/jolt-deploy-gif.gif"

import ProjectImg from '../Image/ProjectImg';

const Contact = () => {
  // const { jolt } = useContext(PortfolioContext);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  const { title, info, info2, info3, url, img, id } = {
  title: 'Jolt',
  info: 'Jolt is an open-source framework for developing, deploying and managing JAMstack applications with serverless functions that run on AWS.',
  info2:
    'I built Jolt with a distributed team of 3 other engineers in 2021. Our aim was to create a tool that made it easy for developers to take advantage of the security, high-scalability and fast response times that the JAMstack model provides while leveraging serverless functions to handle computation that needs to be performed outside of the client.',
  info3:
    "",
  url: 'https://jolt-framework.github.io/',
  repo: 'https://github.com/jolt-framework',
  img: 'jolt_color_logo.png',
  id: nanoid(),
};

  return (
    <section id="jolt">
      <Container>
        <Row key={id}>
          <Col lg={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={800} delay={100} distance="30px">
              <Title className="project-wrapper__text-title section-title" title={title} />
              <div className="project-wrapper__text">
                <div>
                  <p className="mb-4">{info}</p>
                  <p className="mb-4">{info2 || ''}</p>
                </div>
              </div>
            </Fade>
            <Fade left={isDesktop} bottom={isMobile} duration={800} delay={100} distance="30px">
              <div className="project-wrapper__text">
                <div>
                  <p className="mb-4">{info3 || ''}</p>
                </div>
              </div>
            </Fade>
        <Row id="jolt-presentation">
          <figure>
            <iframe
              width="450"
              height="315"
              src="https://www.youtube.com/embed/P5G68KjzIoA"
              title="YouTube video player"
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
              allowFullScreen
            />

            <figcaption align="center" style={{fontSize: "14px"}}>A presentation I gave with my remote team on how we built Jolt</figcaption>
          </figure>
        </Row>
          </Col>
          <Col lg={6} sm={12}>
            <Fade right={isDesktop} bottom={isMobile} duration={800} delay={100} distance="30px">
              <div className="project-wrapper__image">
                <a
                  href={url || '#!'}
                  target="_blank"
                  aria-label="Project Link"
                  rel="noopener noreferrer"
                >
                  <Tilt
                    options={{
                      reverse: false,
                      max: 8,
                      perspective: 1000,
                      scale: 1,
                      speed: 300,
                      transition: true,
                      axis: null,
                      reset: true,
                      easing: 'cubic-bezier(.03,.98,.52,.99)',
                    }}
                  >
                    <div data-tilt className="thumbnail rounded jolt-logo">
                      <ProjectImg alt={title} filename={img} />
                    </div>
                  </Tilt>
                </a>
              </div>
            </Fade>
            <Row>
              <div className="jolt-links">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cta-btn cta-btn--hero"
                  href={url || '#!'}
                >
                  Read The White Paper
                </a>
              </div>
            </Row>
            <Row key={id}>
              <Fade
                right={isDesktop}
                bottom={isMobile}
                duration={800}
                delay={100}
                distance="30px"
              >
                <Tilt
                  options={{
                    reverse: false,
                    max: 8,
                    perspective: 1000,
                    scale: 1,
                    speed: 300,
                    transition: true,
                    axis: null,
                    reset: true,
                    easing: 'cubic-bezier(.03,.98,.52,.99)',
                  }}
                >
                  <div data-tilt className="thumbnail rounded">
                    <img id="jolt-deploy-gif" src={jolt_deploy_gif} alt="Running Jolt Deploy" />
                  </div>
                </Tilt>
              </Fade>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
