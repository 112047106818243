import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
// import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';
import { nanoid } from 'nanoid';

const Projects = () => {
  // const { projects } = useContext(PortfolioContext);
  const projects = [
    {
      id: nanoid(),
      img: 'swello.png',
      title: 'Swello',
      info: 'A full-stack Trello-like SPA for project management. Features drag and drop functionality and more.',
      info2: '',
      languages: 'React/Redux, Node.js/Express, MongoDB',
      url: '',
    },
    {
      id: nanoid(),
      img: 'group-limerick.png',
      title: 'Group-Limerick',
      info: 'A multi-player web app where players take turns writing lines in a limerick before the completed limericks are displayed.',
      info2:
        'This is a popular game in my family, so I built this at the start of the pandemic so we could all play together remotely.',
      languages: 'Ruby/Sinatra, ERB, jQuery, Heroku',
      url: '',
    },
    {
      id: nanoid(),
      img: 'airlines.png',
      title: "Flykstra's Map",
      info: "Displays and filters airline routes on a world map and highlights the shortest path between selected airports using Dijkstra's pathfinding algorithm.",
      info2: '',
      languages: 'Node.js/Express, PostgreSQL, vanilla JavaScript',
      url: '',
    },
  ];

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="Projects" />
          <Row>
            {projects.map((project) => {
              const { title, info, info2, languages, img, id } = project;
              return (
                <Col lg={4} sm={12} key={id}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={800}
                    delay={100}
                    distance="30px"
                  >
                    {isMobile ? (
                      <h3 className="project-wrapper__text-title">{title || 'Project Title'}</h3>
                    ) : (
                      ''
                    )}
                    <div className="project-wrapper__image">
                        <Tilt
                          options={{
                            reverse: false,
                            max: 8,
                            perspective: 1000,
                            scale: 1,
                            speed: 300,
                            transition: true,
                            axis: null,
                            reset: true,
                            easing: 'cubic-bezier(.03,.98,.52,.99)',
                          }}
                        >
                          <div data-tilt className="thumbnail rounded">
                            <ProjectImg alt={title} filename={img} />
                          </div>
                        </Tilt>
                    </div>
                  </Fade>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={800}
                    delay={100}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      {isDesktop ? (
                        <h3 className="project-wrapper__text-title">{title || 'Project Title'}</h3>
                      ) : (
                        ''
                      )}
                      <div>
                        <p>{info}</p>
                        <p className="mb-4">{info2}</p>
                      </div>
                      <p>{`Built with: ${languages}`}</p>
                    </div>
                  </Fade>
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Projects;
